import { useState, useEffect, useMemo } from 'react';
import { IResponseOption, QdfQuestion } from '../../../types';
import { Stack, Button } from '@mui/material';
import EditableQuestionText from '../../EditableQuestionText/EditableQuestionText';
import styles from './Dropdown.module.css';
import ResponseOptionsModal from '../../ResponseOptionsModal/ResponseOptionsModal';
import { useResponseOptionsOrder } from '../../../hooks';

interface DropdownProps {
  onVisualChange: (question: QdfQuestion) => void;
  name: string;
  qdf: QdfQuestion;
}

const Dropdown = ({ onVisualChange, name, qdf }: DropdownProps) => {
  const [edit, setEdit] = useState(false);
  const [responseOptions, setResponseOptions] = useState<IResponseOption[]>([]);
  const options = qdf.options;
  const itemsOrder = useResponseOptionsOrder({ responseOptions, options });

  const onEdit = () => {
    setEdit(!edit);
  };

  const textChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onVisualChange({
      ...qdf,
      text: e.target.value,
    });
  };

  const onSaveData = () => {
    onVisualChange({
      ...qdf,
      responses: {
        element: 'qdf:response_group',
        items: responseOptions,
      },
    });
    setEdit(false);
  };

  useEffect(() => {
    qdf.responses && setResponseOptions(qdf.responses.items);
  }, [qdf]);

  const defaultText = useMemo(() => {
    return options.default_text ? options.default_text : 'Please select one.';
  }, [options.default_text]);

  return (
    <div className="dropdown">
      <Stack spacing={4} style={{ width: '100%' }}>
        <EditableQuestionText onChangeText={textChangeHandler} value={qdf.text} />
        <select defaultValue={defaultText} className={styles['dropdown-field']}>
          <option data-testid="option-default">{defaultText}</option>

          {itemsOrder.map((option: IResponseOption, index: number) => {
            return (
              <option data-testid={`option-${option.text}`} key={index} value={option.text}>
                {option.text}
              </option>
            );
          })}
        </select>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="text" onClick={onEdit}>
            Edit Choices
          </Button>
          <span>{qdf.responses && qdf.responses.items.length} options in list</span>
        </Stack>
      </Stack>

      <ResponseOptionsModal
        onSaveData={onSaveData}
        edit={edit}
        onSetEdit={setEdit}
        responseOptions={responseOptions}
        onSetResponseOptions={setResponseOptions}
        qdf={qdf}
        name={name}
      />
    </div>
  );
};
export default Dropdown;
