import { IResponseOption } from '../../types';
import ResponseCSS from './ResponseOption.module.css';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, IconButton, Stack } from '@mui/material';

interface ResponseOptionProps {
  name: string;
  index: number;
  input: IResponseOption;
  value: string;
  onChange: Function;
  onRemove: Function;
  multiple?: boolean;
  showTickbox: boolean;
  inputRef: React.Ref<HTMLInputElement>;
  onSelectOption: Function;
  checked: boolean;
  onEnterPress?: Function;
}

const ResponseOption = ({
  name,
  index,
  input,
  value,
  onChange,
  onRemove,
  multiple = false,
  showTickbox,
  inputRef,
  onSelectOption,
  checked,
  onEnterPress,
}: ResponseOptionProps) => {
  const type = multiple ? 'checkbox' : 'radio';

  const onInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Call onEnterPress if Enter key is pressed
    if (e.key === 'Enter') {
      onEnterPress && onEnterPress();
    }

    // If backdelete is pressed and the input is empty, delete the current option
    if (e.key === 'Backspace') {
      if (input.text === '') {
        onRemove(index); // Delete the current option
      }
    }
  };

  return (
    <Stack
      className={
        checked ? `${ResponseCSS.option} ${ResponseCSS.selected}` : `${ResponseCSS.option}`
      }
      direction="row"
      spacing={2.5}
      alignItems="center">
      {showTickbox && (
        <span className={ResponseCSS['uniform-response']}>
          <input
            data-testid={`${type}-${index}`}
            className={ResponseCSS.radio}
            type={type}
            name={`${name}-input`}
            onChange={() => {
              onSelectOption(index);
            }}
            value={value}
            checked={checked != null ? checked : false}
          />
        </span>
      )}

      <label className={ResponseCSS['option-label']} htmlFor={`${name}-response-${index}`}>
        <input
          id={`${name}-response-${index}`}
          ref={inputRef}
          className={ResponseCSS['custom-choice']}
          name="option"
          placeholder="Enter choice..."
          value={input.text}
          onChange={(e) => onChange(index, e)}
          onKeyUp={onInputKeyUp}
          autoComplete="off"
        />
      </label>

      <IconButton data-testid="remove-option" color="error" onClick={() => onRemove(index)}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Stack>
  );
};

export default ResponseOption;
