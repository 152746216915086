import React, { useEffect, useState } from 'react';
import { IResponseOption, QdfQuestion } from '../../../types';
import ResponseOptions from '../../ResponseOptions/ResponseOptions';
import EditableQuestionText from '../../EditableQuestionText/EditableQuestionText';
import { Stack } from '@mui/material';

interface SingleProps {
  onVisualChange: (question: QdfQuestion) => void;
  name: string;
  qdf: QdfQuestion;
}

function Single({ onVisualChange, name, qdf }: SingleProps) {
  const [responseOptions, setResponseOptions] = useState<IResponseOption[]>([]);

  const textChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onVisualChange({
      ...qdf,
      text: e.target.value,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    onVisualChange({
      ...qdf,
      responses: {
        element: 'qdf:response_group',
        items: responseOptions,
      },
    });
  }, [onVisualChange, responseOptions]);

  return (
    <Stack className="single" spacing={4} style={{ width: '100%' }}>
      <EditableQuestionText onChangeText={textChangeHandler} value={qdf.text} />
      <form onSubmit={submitHandler}>
        <ResponseOptions
          options={qdf.options}
          responseOptions={qdf?.responses?.items}
          onChange={setResponseOptions}
          name={name}
          showTickbox={true}
        />
      </form>
    </Stack>
  );
}

export default Single;
