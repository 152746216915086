import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridHeader from './GridHeader';
import { IResponseOption, QdfQuestion } from '../../../types';
import { getSortedArr, showSample } from '../../../containers/WidgetBuilder/util';
import CollapsedColumnOptions from './CollapsedColumnOptions';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useGridRowsHandler } from '../../../hooks';

interface CollapsedGridOptionsProps {
  widgetName: string;
  name: string;
  columns?: IResponseOption[];
  options: Record<string, any>;
  onChangeColumns: (inputFields: IResponseOption[]) => void;
  onChangeRows: (inputFields: QdfQuestion[]) => void;
  rows?: QdfQuestion[];
  onSetAnswers: Function;
  answers: Record<string, number | string | null>;
}
const CollapsedGridOptions = ({
  widgetName,
  name,
  columns,
  options,
  onChangeColumns,
  onChangeRows,
  rows = [],
  answers,
  onSetAnswers,
}: CollapsedGridOptionsProps) => {
  const { addRows, rowTextHandler, removeRows } = useGridRowsHandler({ name, rows, onChangeRows });

  const orderRows = () => {
    const order =
      options['custom_roworder'] && options['custom_roworder'].match('^[0-9,]*$')
        ? getSortedArr(rows, options['custom_roworder'])
        : rows;

    //Rowsample provides the number of rows to be randomly shown
    if (options.rowsample) return showSample(order, options.rowsample);

    //Displaymax provides the number of rows to be shown
    if (options.displaymax) {
      return order.filter((item: QdfQuestion, index: number) => index < options.displaymax);
    }
    return order;
  };

  return (
    <Stack alignItems="center" width="100%" spacing={2} data-testid="collapsed-grid">
      {orderRows().map((row: QdfQuestion) => (
        <Stack key={row.name} direction="row" spacing={1} width="100%">
          <Accordion style={{ width: '100%' }} defaultExpanded={options['collapse_expanded']}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <GridHeader
                index={Number(row.name.split('_')[1])}
                scope="row"
                onChangeText={rowTextHandler}
                onRemove={removeRows}
                cell={row}
                textAlign="left"
                hideRemoveBtn
                width="100%"
              />
            </AccordionSummary>
            <AccordionDetails>
              <CollapsedColumnOptions
                widgetName={widgetName}
                row={row}
                columns={columns}
                options={options}
                onChangeColumns={onChangeColumns}
                onSetAnswers={onSetAnswers}
                answers={answers}
              />
            </AccordionDetails>
          </Accordion>

          <Stack justifyContent="center">
            <IconButton color="error" onClick={() => removeRows(Number(row.name.split('_')[1]))}>
              <RemoveCircleIcon data-testid={`remove-row`} />
            </IconButton>
          </Stack>
        </Stack>
      ))}
      <Button fullWidth color="success" onClick={addRows}>
        Add row
      </Button>
    </Stack>
  );
};
export default CollapsedGridOptions;
