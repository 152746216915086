import { Autocomplete, TextField } from '@mui/material';
import { QdfQuestion } from '../../types';
import { QuestionType } from '../../types/panoptic';

interface EnumControlProps {
  optionName: string;
  onOptionChange: Function;
  qdf: QdfQuestion;
  widgetDefinitions: QuestionType;
}

const EnumControl = ({ optionName, onOptionChange, qdf, widgetDefinitions }: EnumControlProps) => {
  const options = widgetDefinitions.options[optionName].values;

  const value =
    typeof qdf.options[optionName] === 'undefined' || !options.includes(qdf.options[optionName])
      ? null
      : qdf.options[optionName];

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onOptionChange({
      ...qdf.options,
      [optionName]: e.target.value,
    });
  };

  return (
    <Autocomplete
      freeSolo
      data-testid={`enum-${optionName}`}
      fullWidth
      size="small"
      options={options}
      value={value}
      renderInput={(params) => <TextField {...params} label={optionName} />}
      onSelect={onSelect}
    />
  );
};

export default EnumControl;
