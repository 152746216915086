import { useState } from 'react';

export const useSingleAnswersHandler = () => {
  const [singleAnswers, setSingleAnswers] = useState<Record<string, boolean>>({});

  const onSelectSingleAnswer = (index: number) => {
    const answersAfterAddingOption = {
      ...singleAnswers,
      [index]: true,
    };

    setSingleAnswers(answersAfterAddingOption);

    for (let answer in answersAfterAddingOption) {
      if (answer !== index.toString() && singleAnswers[answer] === true) {
        setSingleAnswers({ ...answersAfterAddingOption, [answer]: false });
      }
    }
  };
  return { singleAnswers, onSelectSingleAnswer };
};
