import { useEffect, useRef } from 'react';
import { IResponseOption } from '../../types';
import ResponseOption from '../ResponseOption/ResponseOption';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Stack } from '@mui/material';
import {
  useResponseOptionsHandler,
  useMultiAnswersHandler,
  useResponseOptionsOrder,
  useSingleAnswersHandler,
} from '../../hooks';

interface ResponseOptionsProps {
  responseOptions?: IResponseOption[];
  onChange: (inputFields: IResponseOption[]) => void;
  multiple?: boolean;
  showTickbox: boolean;
  name: string;
  options: Record<string, any>;
}

function ResponseOptions({
  responseOptions = [],
  onChange,
  showTickbox,
  multiple,
  name,
  options,
}: ResponseOptionsProps) {
  const { singleAnswers, onSelectSingleAnswer } = useSingleAnswersHandler();
  const { multiAnswers, onSelectMultiAnswer } = useMultiAnswersHandler(responseOptions, options);

  const inputEl = useRef<null | HTMLInputElement>(null);

  const { addResponses, removeResponses, responseTextHandler } = useResponseOptionsHandler({
    responseOptions,
    onChange,
  });
  const itemsOrder = useResponseOptionsOrder({ responseOptions, options });

  // Whenever a field is removed or added, focus on the added field
  useEffect(() => {
    inputEl.current?.focus();
  }, [responseOptions.length]);

  let optionsWidth = options.horizontal ? `${100 / options.columns}%` : '100%';
  const minOptionsWidth = options.horizontal ? `${100 / options.min_columns}%` : '100%';

  if (options.columns < options.min_columns) {
    optionsWidth = minOptionsWidth;
  }

  //If wrap is toggled, the number of columns will be dynamically reduced according to the screen size
  const optionsFlexBasis = options.wrap ? optionsWidth : 'auto';

  return (
    <div>
      <Stack
        direction={options.horizontal ? 'row' : 'column'}
        flexWrap="wrap"
        data-testid="options-flex-container">
        {itemsOrder.map((item: IResponseOption) => {
          return (
            <Stack
              data-testid={`response-option-${item.code}`}
              flexBasis={optionsFlexBasis}
              width={{ xs: minOptionsWidth, md: optionsWidth }}
              key={item.code}>
              <ResponseOption
                input={item}
                index={item.code}
                onChange={responseTextHandler}
                onRemove={removeResponses}
                value={`${item.code}`}
                multiple={multiple}
                showTickbox={showTickbox}
                name={name}
                inputRef={inputEl}
                onSelectOption={multiple ? onSelectMultiAnswer : onSelectSingleAnswer}
                checked={multiple ? multiAnswers[item.code] : singleAnswers[item.code]}
                onEnterPress={addResponses}
              />
            </Stack>
          );
        })}
      </Stack>
      <Button onClick={addResponses} startIcon={<AddCircleIcon />}>
        Add {responseOptions.length > 0 && 'another'} option
      </Button>
    </div>
  );
}

export default ResponseOptions;
