import { IconButton, Stack } from '@mui/material';
import { IResponseOption, QdfQuestion } from '../../../types';
import styles from './GridHeader.module.css';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface GridHeaderProps {
  index: number;
  scope: string;
  onChangeText: Function;
  onRemove: Function;
  cell: QdfQuestion | IResponseOption;
  width?: string;
  textAlign?: 'initial' | 'center' | 'left' | 'right';
  hideRemoveBtn?: boolean;
}

const GridHeader = ({
  index,
  scope,
  onChangeText,
  onRemove,
  cell,
  textAlign,
  hideRemoveBtn,
  width,
}: GridHeaderProps) => {
  return (
    <Stack
      className={styles['header-wrapper']}
      direction="row"
      justifyContent={textAlign === 'left' ? 'flex-start' : 'center'}
      alignItems="center"
      flexWrap="wrap">
      <label className={styles.label} style={{ width: width }}>
        <input
          className={styles['header-input']}
          name={cell.text}
          placeholder={`Enter ${scope}`}
          onChange={(e) => onChangeText(index, e)}
          value={cell.text}
          style={{ textAlign: textAlign }}
        />
      </label>
      {!hideRemoveBtn && (
        <span className={styles['remove-btn-wrapper']}>
          <IconButton color="error" onClick={() => onRemove(index)}>
            <RemoveCircleIcon data-testid={`remove-${scope}`} />
          </IconButton>
        </span>
      )}
    </Stack>
  );
};

export default GridHeader;
