import { QdfQuestion } from '../types';

interface useGridRowsHandlerArgs {
  name: string;
  rows: QdfQuestion[];
  onChangeRows: (inputFields: QdfQuestion[]) => void;
}

export const useGridRowsHandler = ({ name, rows, onChangeRows }: useGridRowsHandlerArgs) => {
  const rowCode = rows.length > 0 ? Number(rows[rows.length - 1].name.split('_')[1]) + 1 : 0;

  const findRowIndexByCode = (rows: QdfQuestion[], code: number) => {
    return rows.findIndex((row) => Number(row.name.split('_')[1]) === code);
  };

  const addRows = () => {
    onChangeRows([
      ...rows,
      {
        options: {},
        text: '',
        name: `${name}_${rowCode}`,
        type: 'grid-item',
        element: 'qdf:question',
        export: [{ name: `${name}_${rowCode}` }],
      },
    ]);
  };

  const rowTextHandler = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const data = [...rows];
    const foundIndex = findRowIndexByCode(data, index);
    data[foundIndex] = { ...data[foundIndex], text: e.target.value };
    onChangeRows(data);
  };

  const removeRows = (index: number) => {
    const data = [...rows];
    const foundIndex = findRowIndexByCode(data, index);
    data.splice(foundIndex, 1);
    onChangeRows(data);
  };
  return { addRows, rowTextHandler, removeRows };
};
