import { IResponseOption, QdfQuestion } from '../../../types';
import styles from './GridButton.module.css';

interface GridButtonProps {
  row: QdfQuestion;
  column: IResponseOption;
  widgetName: string;
  onSetAnswers?: Function;
  checked?: boolean;
}

const GridButton = ({ row, column, widgetName, onSetAnswers, checked }: GridButtonProps) => {
  const handleAnswers = () => onSetAnswers && onSetAnswers(row.name, column.code);

  return (
    <div className={styles['cell-wrapper']}>
      <label className={styles['cell-label']} htmlFor={`${row.name}-response-${column.code}`}>
        <div className={styles['radio-wrapper']}>
          <span>
            <input
              checked={checked}
              onChange={handleAnswers}
              data-testid={`${row.name}-response-${column.code}`}
              className={styles.radio}
              id={`${row.name}-response-${column.code}`}
              type={widgetName === 'grid-check' ? 'checkbox' : 'radio'}
              value={column.code}
              name={`${row.name}-response`}
              data-column={column.code}
              aria-label={`${row.text}: ${column.text}`}
            />
          </span>
        </div>
      </label>
    </div>
  );
};

export default GridButton;
