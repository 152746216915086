import { Stack, Switch } from '@mui/material';
import { QdfQuestion } from '../../types';

interface BooleanControlProps {
  optionName: string;
  qdf: QdfQuestion;
  onOptionChange: Function;
}

const BooleanControl = ({ optionName, qdf, onOptionChange }: BooleanControlProps) => {
  const isChecked = typeof qdf.options[optionName] === 'boolean' ? qdf.options[optionName] : false;

  const onChange = () =>
    onOptionChange({
      ...qdf.options,
      [optionName]: !qdf.options[optionName],
    });

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <span>{optionName}</span>

      <Switch
        data-testid={`${optionName}-toggle`}
        value={qdf.options[optionName]}
        checked={isChecked}
        onChange={onChange}
      />
    </Stack>
  );
};

export default BooleanControl;
