import { IResponseOption } from '../types';
import { useMemo } from 'react';
interface useOffsetHandlerArgs {
  columns: IResponseOption[];
  offset: number;
}

export const useOffsetHandler = ({ columns, offset }: useOffsetHandlerArgs) => {
  const offsetFn = (arr: any[], new_index: number) => {
    const newArr: IResponseOption[] = [{ element: 'qdf:response', code: -1, text: null }, ...arr];

    newArr.splice(new_index, 0, newArr.splice(0, 1)[0]);

    return newArr;
  };
  return useMemo(() => {
    return offsetFn(columns, offset);
  }, [offset, columns]);
};
