import { Collapse, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { QdfQuestion } from '../../types';
import { QuestionType } from '../../types/panoptic';
import { Option } from '../../types/panoptic';

import BooleanControl from './BooleanControl';
import EnumControl from './EnumControl';
import IntControl from './IntControl';
import ListOfIntControl from './ListOfIntControl';
import OptionInfo from './OptionInfo';
import styles from './Sidepanel.module.css';
import StrControl from './StrControl';

import { useSelector } from 'react-redux';
import { RootState } from '../../state';

interface SidepanelProps {
  widgetDefinitions: QuestionType;
  qdf: QdfQuestion;
  onVisualChange: Function;
}

interface OptionValue {
  Component: typeof React.Component;
}

type OptionComponent = Record<string, OptionValue>;

const Sidepanel = ({ widgetDefinitions, qdf, onVisualChange }: SidepanelProps) => {
  const options: OptionComponent = {
    bool: {
      Component: BooleanControl as unknown as typeof React.Component,
    },
    int: {
      Component: IntControl as unknown as typeof React.Component,
    },
    str: {
      Component: StrControl as unknown as typeof React.Component,
    },
    enum: {
      Component: EnumControl as unknown as typeof React.Component,
    },
    list_of_int: {
      Component: ListOfIntControl as unknown as typeof React.Component,
    },
  };

  const supportedOptionTypes = Object.keys(options);

  const getOptionType = (option: Option | string) => {
    return typeof option === 'string' ? option : option.type;
  };

  const defaultOptions = Object.keys(widgetDefinitions.options).reduce((acc, optionName) => {
    if (supportedOptionTypes.includes(getOptionType(widgetDefinitions.options[optionName]))) {
      if (widgetDefinitions.options[optionName].default == null) {
        return { ...acc, [optionName]: '' };
      }
      return { ...acc, [optionName]: widgetDefinitions.options[optionName].default };
    }
    return acc;
  }, {});

  const [qdfOptions, setQdfOptions] = useState(defaultOptions);

  useEffect(() => {
    onVisualChange({
      ...qdf,
      options: qdfOptions,
    });
  }, [qdfOptions, onVisualChange]);

  const shownOptionsPanel = useSelector((state: RootState) => state.root.shownOptionsPanel);

  return (
    <Collapse
      orientation="horizontal"
      in={shownOptionsPanel}
      timeout={250}
      data-testid="side-panel-wrapper">
      <Stack className={styles.panel} spacing={1} data-testid="side-panel">
        <p className={styles.title}>Settings</p>
        {Object.keys(widgetDefinitions.options).map((optionName) => {
          if (supportedOptionTypes.includes(getOptionType(widgetDefinitions.options[optionName]))) {
            const { Component } = options[getOptionType(widgetDefinitions.options[optionName])];
            return (
              <Stack direction="row" key={optionName}>
                <Component
                  optionName={optionName}
                  qdf={qdf}
                  widgetDefinitions={widgetDefinitions}
                  onOptionChange={setQdfOptions}
                  min={widgetDefinitions.options[optionName].min}
                  max={widgetDefinitions.options[optionName].max}
                />
                <OptionInfo
                  type={getOptionType(widgetDefinitions.options[optionName])}
                  description={widgetDefinitions.options[optionName].description}
                />
              </Stack>
            );
          } else
            return (
              <span className={styles['unsupported-option']} key={optionName}>
                {optionName}
              </span>
            );
        })}
      </Stack>
    </Collapse>
  );
};

export default Sidepanel;
