import { useCallback, useState } from 'react';
import { QdfQuestion } from '../types';

interface useQdfParserArgs {
  initialQdf: QdfQuestion;
}

interface useQdfParserResponse {
  qdf: QdfQuestion;
  onVisualChange: Function;
  onQdfStringChange: Function;
}

export const useQdfParser = ({ initialQdf }: useQdfParserArgs): useQdfParserResponse => {
  const [questionQdf, setQuestionQdf] = useState<QdfQuestion>(initialQdf);

  const onVisualChange = useCallback((question: QdfQuestion) => {
    setQuestionQdf(question);
  }, []);

  const onQdfStringChange = useCallback((qdf: string) => {
    setQuestionQdf(JSON.parse(qdf));
  }, []);

  return { qdf: questionQdf, onVisualChange, onQdfStringChange };
};
