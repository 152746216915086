import { useMemo } from 'react';
import {
  getSortedArr,
  getSubstringsBetweenParens,
  randomizeArr,
  reverseArr,
  rotateArr,
  showSample,
} from '../containers/WidgetBuilder/util';
import { IResponseOption, QdfQuestion } from '../types';

interface nameOverridesProps {
  order: 'order' | 'colorder' | 'roworder';
  sample: 'sample' | 'colsample' | 'rowsample';
}

interface useResponseOptionsOrderArgs {
  responseOptions: IResponseOption[] | QdfQuestion[];
  options: Record<string, any>;
  nameOverrides?: nameOverridesProps;
}

export const useResponseOptionsOrder = ({
  responseOptions,
  options,
  nameOverrides,
}: useResponseOptionsOrderArgs) => {
  const orderOption = nameOverrides ? nameOverrides.order : 'order';
  const sampleOption = nameOverrides ? nameOverrides.sample : 'sample';
  const customOrder = `custom_${orderOption}`;

  const itemsOrder = useMemo(() => {
    let order = [];

    if (options[`custom_${orderOption}`] && options[`custom_${orderOption}`].match('^[0-9,]*$')) {
      order = getSortedArr(responseOptions, options[`custom_${orderOption}`]);
    } else if (options[orderOption] === 'randomize') {
      order = randomizeArr(responseOptions);
    } else if (options[orderOption] === 'reverse') {
      order = reverseArr(responseOptions);
    } else if (options[orderOption] === 'rotate') {
      order = rotateArr(responseOptions);
    } else if (options[orderOption] && options[orderOption].includes('custom(')) {
      const customOrder = getSubstringsBetweenParens(options[orderOption]);
      if (customOrder.match('^[0-9,]*$')) {
        order = getSortedArr(responseOptions, customOrder);
      } else order = responseOptions;
    } else order = responseOptions;

    //Sample provides the number of items to be randomly shown
    if (options[sampleOption] && options[sampleOption] < responseOptions.length) {
      return showSample(order, options[sampleOption]);
    }

    //set_active option shows only responses within the provided list
    if (options.set_active) {
      const set_active_Arr = [...options.set_active];
      if (set_active_Arr.length > 0) {
        return order.filter((item, index) => set_active_Arr.includes(index.toString()));
      }
    }

    //Mask option hides responses within the provided list
    if (options.mask) {
      const maskArr = [...options.mask];
      if (maskArr.length > 0) {
        return order.filter((item, index) => !maskArr.includes(index.toString()));
      }
    }

    //Displaymax provides the number of rows to be shown
    if (options.displaymax) {
      return order.filter((item: IResponseOption, index: number) => index < options.displaymax);
    }
    return order;
  }, [
    responseOptions,
    options[orderOption],
    options[customOrder],
    options[sampleOption],
    options.set_active,
    options.mask,
    options.displaymax,
  ]);

  return itemsOrder;
};
