import { IResponseOption } from '../types';

interface useResponseOptionsHandlerArgs {
  responseOptions: IResponseOption[];
  onChange: (inputFields: IResponseOption[]) => void;
}

export const useResponseOptionsHandler = ({
  responseOptions,
  onChange,
}: useResponseOptionsHandlerArgs) => {
  const code =
    responseOptions.length > 0 ? responseOptions[responseOptions.length - 1].code + 1 : 0;

  const findColumnIndexByCode = (columns: IResponseOption[], code: number) => {
    return columns.findIndex((element) => element.code === code);
  };

  const addResponses = () => {
    onChange([...responseOptions, { text: '', code: code, element: 'qdf:response' }]);
  };

  const responseTextHandler = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const data = [...responseOptions];
    const foundIndex = findColumnIndexByCode(data, index);
    data[foundIndex] = { ...data[foundIndex], text: e.target.value };
    onChange(data);
  };

  const removeResponses = (index: number) => {
    const data = [...responseOptions];
    const foundIndex = findColumnIndexByCode(data, index);
    data.splice(foundIndex, 1);
    onChange([...data]);
  };

  return { addResponses, responseTextHandler, removeResponses };
};
