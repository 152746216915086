import { createSlice } from '@reduxjs/toolkit';

interface RootSliceState {
  shownOptionsPanel: boolean;
}

const initialState: RootSliceState = {
  shownOptionsPanel: false,
};

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    toggleOptionsPanel: (state) => ({
      shownOptionsPanel: !state.shownOptionsPanel,
    }),
  },
});

export const { toggleOptionsPanel } = rootSlice.actions;
