import { Button, Stack, CircularProgress, Alert } from '@mui/material';
import { useState } from 'react';
import Sidepanel from '../../components/Sidepanel/Sidepanel';
import { useQdfParser } from '../../hooks';
import { widgets } from '../../lib/widgets';

import { useGetOptionDefinitionsQuery } from '../../services/panoptic-api';
import { QuestionType } from '../../types/panoptic';
import { filterDefinitionFile } from './util';

import styles from './WidgetBuilder.module.css';

interface WidgetProps {
  widgetName: string;
  name: string;
}

const widgetNames = widgets.map(({ name }) => name);

export const WidgetBuilder = ({ widgetName, name }: WidgetProps) => {
  const { data, isLoading, error } = useGetOptionDefinitionsQuery();
  let widgetDefinitions: QuestionType = { options: {}, export: [] };

  !isLoading && !error && (widgetDefinitions = filterDefinitionFile(widgetName, data));

  const widget = widgets.find(({ name }) => widgetName === name);

  if (!widget) {
    throw new Error('Widget does not exist');
  }

  const { qdf, onVisualChange, onQdfStringChange } = useQdfParser({
    initialQdf: widget?.getInitialQdf(name),
  });

  const [showQdf, setShowQdf] = useState(false);

  if (!widget) {
    return (
      <div>
        Widget {widgetName} is still not supported, only {widgetNames.join(', ')} are currently
        supported.
      </div>
    );
  }

  const { Component } = widget;

  return (
    <div className={styles.wrap}>
      <Stack className={styles.container} direction="row" spacing="auto">
        <Stack className={styles.widget} direction="column" spacing={10}>
          <Component
            onVisualChange={onVisualChange}
            name={name}
            qdf={qdf}
            widgetName={widgetName}
            widgetDefinitions={widgetDefinitions}
          />
          <Button
            variant="text"
            size="small"
            data-testid="toggle-qdf"
            onClick={() => setShowQdf(!showQdf)}>
            {showQdf ? 'Hide QDF (toggle to refresh)' : 'Show QDF'}
          </Button>
          {showQdf && (
            <textarea
              rows={20}
              data-testid="qdf-textarea"
              className={styles.qdfEditor}
              onChange={(e) => onQdfStringChange(e.target.value)}
              defaultValue={JSON.stringify(qdf, null, 2)}></textarea>
          )}
        </Stack>
      </Stack>
      <div className={styles.sidebar}>
        {isLoading ? (
          <div style={{ marginRight: '10px' }}>
            <CircularProgress size={30} />
          </div>
        ) : error ? (
          <div style={{ marginRight: '10px' }}>
            <Alert severity="error">The panel could not be loaded.</Alert>
          </div>
        ) : (
          <Sidepanel
            widgetDefinitions={widgetDefinitions}
            qdf={qdf}
            onVisualChange={onVisualChange}
          />
        )}
      </div>
    </div>
  );
};
