import { IconButton } from '@mui/material';
import { IResponseOption } from '../../../types';
import GridHeader from './GridHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './ColumnOptions.module.css';
import { useResponseOptionsHandler, useOffsetHandler } from '../../../hooks';

interface OffsetColumnsProps {
  columns?: IResponseOption[];
  onChangeColumns: (inputFields: IResponseOption[]) => void;
  options: Record<string, any>;
}

const OffsetColumns = ({ columns = [], onChangeColumns, options }: OffsetColumnsProps) => {
  const { addResponses, removeResponses, responseTextHandler } = useResponseOptionsHandler({
    responseOptions: columns,
    onChange: onChangeColumns,
  });

  const offset = options.offset;
  const offsetOrder = useOffsetHandler({ columns, offset });

  return (
    <tr data-testid="offset-columns">
      {offsetOrder.map((column: IResponseOption) =>
        column.code === -1 ? (
          <td key={column.code}></td>
        ) : (
          <th
            className={styles['header-cell']}
            align="center"
            scope="column"
            style={{ width: options.width > 0 && options.width }}
            key={column.code}>
            <GridHeader
              cell={column}
              index={column.code}
              scope="column"
              onChangeText={responseTextHandler}
              onRemove={removeResponses}
            />
          </th>
        )
      )}
      <td align="right">
        <IconButton color="success" onClick={addResponses}>
          <AddCircleIcon data-testid="add-column" />
        </IconButton>
      </td>
    </tr>
  );
};

export default OffsetColumns;
