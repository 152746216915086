import { TextField } from '@mui/material';
import { QdfQuestion } from '../../types';

interface StrControlProps {
  optionName: string;
  onOptionChange: Function;
  qdf: QdfQuestion;
}

const StrControl = ({ optionName, onOptionChange, qdf }: StrControlProps) => {
  const value = typeof qdf.options[optionName] === 'undefined' ? '' : qdf.options[optionName];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onOptionChange({
      ...qdf.options,
      [optionName]: e.target.value,
    });
  };

  return (
    <TextField
      data-testid={`str-input-${optionName}`}
      fullWidth
      type="text"
      label={optionName}
      size="small"
      value={value}
      onChange={onChange}
    />
  );
};

export default StrControl;
