import { TextField } from '@mui/material';
import { QdfQuestion } from '../../types';

interface IntControlProps {
  optionName: string;
  onOptionChange: Function;
  qdf: QdfQuestion;
  min?: number;
  max?: number;
}

const IntControl = ({ optionName, onOptionChange, qdf, min, max }: IntControlProps) => {
  const value = typeof qdf.options[optionName] === 'undefined' ? '' : qdf.options[optionName];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((min && Number(e.target.value) < min) || (max && Number(e.target.value) > max)) {
      alert(`${optionName} value must be between ${min} and ${max}`);
    } else
      onOptionChange({
        ...qdf.options,
        [optionName]: Number(e.target.value),
      });
  };
  return (
    <TextField
      data-testid={`${optionName}-input-number`}
      fullWidth
      type="number"
      label={optionName}
      size="small"
      value={value}
      onChange={onChange}
      InputProps={{
        inputProps: {
          min: min,
          max: max,
        },
      }}
    />
  );
};

export default IntControl;
