import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PanopticQdfAPIResponse } from '../types/panoptic';

export const panopticApi = createApi({
  reducerPath: 'panopticApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://panoptic-ldc.yougov.net',
  }),
  endpoints: (builder) => ({
    getOptionDefinitions: builder.query<PanopticQdfAPIResponse, void>({
      query: () => `/questionnaires/typespecs/initial.qdf`,
    }),
  }),
});

export const { useGetOptionDefinitionsQuery } = panopticApi;
