import { PanopticQdfAPIResponse, QuestionType } from '../../types/panoptic';

export const filterDefinitionFile = (widgetName: string, data: PanopticQdfAPIResponse | any) => {
  const definitionFile = data.question_types;

  const widgetDefinitions: QuestionType = Object.keys(definitionFile).reduce(
    (acc, questionName) => {
      if (questionName === widgetName) return { ...acc, ...definitionFile[questionName] };

      return acc;
    },
    { options: {}, export: [] }
  );

  return widgetDefinitions;
};

export const getSubstringsBetweenParens = (str: string) => {
  let chars = '';

  let startIndex = 0;
  let endIndex = 0;
  let isInsideParens = false;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === '(') {
      if (!isInsideParens) {
        startIndex = i + 1;
        isInsideParens = true;
      }
    } else if (str[i] === ')') {
      if (isInsideParens) {
        endIndex = i;
        isInsideParens = false;

        const substring = str.substring(startIndex, endIndex);
        chars += substring;
      }
    }
  }

  return chars;
};

export const getSortedArr = (arr: any[], customOrder: string) => {
  const customOrderArr = Array.from(
    new Set(customOrder.split(',').filter((char) => arr[Number(char)]))
  );

  const orderedItems = customOrderArr.map((position) => arr[Number(position)]);

  for (const item of arr) {
    if (!orderedItems.includes(item)) orderedItems.push(item);
  }

  return orderedItems;
};

export const randomizeArr = (arr: any[]) => {
  const newArr = [...arr];

  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
};

export const rotateArr = (arr: any[]) => {
  const newArr = [...arr];

  const randomNumber = Math.floor(Math.random() * arr.length);

  for (let i = 0; i < randomNumber; i++) {
    newArr.push(newArr.shift());
  }

  return newArr;
};

export const reverseArr = (arr: any[]) => {
  return [...arr].reverse();
};

export const showSample = (arr: any[], itemsToShow: number) => {
  if (itemsToShow > arr.length) itemsToShow = arr.length;

  const newArr = [...arr];
  const itemsToRemove = newArr.length - itemsToShow;

  for (let i = 0; i < itemsToRemove; i++) {
    newArr.splice(Math.floor(Math.random() * newArr.length), 1);
  }
  return newArr;
};
