import { configureStore } from '@reduxjs/toolkit';
import { panopticApi } from '../services/panoptic-api';
import { rootSlice } from './root';

export const store = configureStore({
  reducer: {
    [panopticApi.reducerPath]: panopticApi.reducer,
    root: rootSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(panopticApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
