import { IResponseOption, QdfQuestion } from '../../../types';
import styles from './GridOpen.module.css';

interface GridOpenProps {
  row: QdfQuestion;
  column: IResponseOption;
  width?: number;
  onSetAnswers: Function;
  answer: number | string | null;
  type: string;
}

const GridOpen = ({ row, column, width, onSetAnswers, answer, type }: GridOpenProps) => {
  return (
    <div className={styles['cell-wrapper']}>
      <label className={styles['cell-label']} htmlFor={`${row.name}-response-${column.code}`}>
        <input
          data-testid="response-field"
          style={{ width: width }}
          className={styles['input-field']}
          id={`${row.name}-response-${column.code}`}
          type={type}
          value={answer!}
          data-column={column.code}
          name={`${row.name}-response`}
          aria-label={`${row.text}: ${column.text}`}
          onChange={(e) => {
            onSetAnswers(row.name, column.code, e.target.value);
          }}
        />
      </label>
    </div>
  );
};

export default GridOpen;
