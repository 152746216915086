import { getSortedArr, showSample } from '../../../containers/WidgetBuilder/util';
import { useResponseOptionsHandler } from '../../../hooks';
import { IResponseOption, QdfQuestion } from '../../../types';
import GridButton from './GridButton';
import GridHeader from './GridHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './RowOptions.module.css';
import { IconButton } from '@mui/material';
import GridOpen from './GridOpen';

interface TransposedGridColumnsProps {
  columns?: IResponseOption[];
  rows?: QdfQuestion[];
  options: Record<string, any>;
  widgetName: string;
  onChangeColumns: (inputFields: IResponseOption[]) => void;
  onSetAnswers: Function;
  answers: Record<string, number | string | null>;
}

const TransposedGridColumns = ({
  columns = [],
  rows = [],
  options,
  widgetName,
  answers,
  onChangeColumns,
  onSetAnswers,
}: TransposedGridColumnsProps) => {
  const { addResponses, removeResponses, responseTextHandler } = useResponseOptionsHandler({
    responseOptions: columns,
    onChange: onChangeColumns,
  });

  const columnsOrder =
    options['custom_colorder'] && options['custom_colorder'].match('^[0-9,]*$')
      ? getSortedArr(columns, options['custom_colorder'])
      : columns;

  return (
    <>
      {(options.colsample ? showSample(columnsOrder, options.colsample) : columnsOrder).map(
        (column: IResponseOption, index: number) => (
          <tr
            data-testid="transposed-columns"
            className={options.stripes && index % 2 === 0 ? styles['row-odd'] : styles.row}
            key={column.code}>
            <th className={styles['header-cell']} align="center" scope="row">
              <GridHeader
                cell={column}
                scope="column"
                index={column.code}
                onChangeText={responseTextHandler}
                onRemove={removeResponses}
              />
            </th>
            {(options.rowsample ? showSample(rows, options.rowsample) : rows)?.map(
              (row, index) =>
                (options.displaymax ? index < options.displaymax : true) && (
                  <td key={`${row.name}-${column.code}`} className={styles['table-cell']}>
                    {widgetName === 'grid-open' ? (
                      <GridOpen
                        row={row}
                        column={column}
                        onSetAnswers={onSetAnswers}
                        type={options.colrank || options.rowrank ? 'number' : 'text'}
                        answer={
                          answers[`${row.name}-${column.code}`] == null
                            ? ''
                            : answers[`${row.name}-${column.code}`]
                        }
                      />
                    ) : (
                      <GridButton widgetName={widgetName} column={column} row={row} />
                    )}
                  </td>
                )
            )}
          </tr>
        )
      )}
      <tr>
        <td>
          <IconButton onClick={addResponses} color="success">
            <AddCircleIcon data-testid="add-column" />
          </IconButton>
        </td>
      </tr>
    </>
  );
};

export default TransposedGridColumns;
