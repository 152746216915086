import {
  useGridAnswersHandler,
  useGridCheckAnswersHandler,
  useGridOpenAnswersHandler,
} from '../../../hooks';
import { IResponseOption, QdfQuestion } from '../../../types';
import CollapsedGridOptions from './CollapsedGridOptions';
import ColumnOptions from './ColumnOptions';
import OffsetColumns from './OffsetColumns';
import OffsetRows from './OffsetRows';
import RowOptions from './RowOptions';
import TransposedGridColumns from './TransposedGridColumns';
import TransposedGridRows from './TransposedGridRows';
import styles from './GridWidget.module.css';
import { useMediaQuery, useTheme } from '@mui/material';

interface RenderGridChildProps {
  widgetName: string;
  name: string;
  onVisualChange: (question: QdfQuestion) => void;
  qdf: QdfQuestion;
}

const RenderGridChild = ({ widgetName, name, onVisualChange, qdf }: RenderGridChildProps) => {
  const columns = qdf.responses?.items;
  const rows = qdf.subquestion;

  const { answers, onSelectAnswer } = useGridAnswersHandler(qdf);
  const { gridCheckAnswers, onSelectGridCheckAnswer } = useGridCheckAnswersHandler({ qdf, rows });
  const { gridOpenAnswers, onSelectGridOpenAnswer } = useGridOpenAnswersHandler({
    qdf,
    rows,
    columns,
  });

  const onChangeColumns = (columns: IResponseOption[]) => {
    onVisualChange({
      ...qdf,
      responses: {
        element: 'qdf:response_group',
        items: columns,
      },
    });
  };

  const onChangeRows = (rows: QdfQuestion[]) => {
    onVisualChange({
      ...qdf,
      subquestion: rows,
    });
  };

  const getAnswersType = () => {
    if (widgetName === 'grid-open') return gridOpenAnswers;
    else if (widgetName === 'grid-check') return gridCheckAnswers;
    return answers;
  };

  const onSetAnswers = () => {
    if (widgetName === 'grid-open') return onSelectGridOpenAnswer;
    else if (widgetName === 'grid-check') return onSelectGridCheckAnswer;
    return onSelectAnswer;
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(qdf.options['collapse_width']));
  const collapsible = matches && qdf.options.collapsible;

  if (collapsible) {
    return (
      <CollapsedGridOptions
        widgetName={widgetName}
        name={name}
        options={qdf.options}
        columns={columns}
        rows={rows}
        onChangeColumns={onChangeColumns}
        onChangeRows={onChangeRows}
        onSetAnswers={onSetAnswers()}
        answers={getAnswersType()}
      />
    );
  } else if (qdf.options.transpose) {
    return (
      <table className={styles.table}>
        <thead>
          <TransposedGridRows
            rows={rows}
            onChangeRows={onChangeRows}
            options={qdf.options}
            name={name}
          />
        </thead>
        <tbody>
          <TransposedGridColumns
            options={qdf.options}
            widgetName={widgetName}
            columns={columns}
            onChangeColumns={onChangeColumns}
            rows={rows}
            onSetAnswers={onSetAnswers()}
            answers={getAnswersType()}
          />
        </tbody>
      </table>
    );
  } else if (qdf.options.offset) {
    return (
      <table className={styles.table}>
        <thead>
          <OffsetColumns
            columns={columns}
            onChangeColumns={onChangeColumns}
            options={qdf.options}
          />
        </thead>
        <tbody>
          <OffsetRows
            options={qdf.options}
            widgetName={widgetName}
            name={name}
            rows={rows}
            columns={columns}
            onChangeRows={onChangeRows}
            onSetAnswers={onSetAnswers()}
            answers={getAnswersType()}
          />
        </tbody>
      </table>
    );
  }
  return (
    <table className={styles.table}>
      <thead>
        <ColumnOptions columns={columns} onChangeColumns={onChangeColumns} options={qdf.options} />
      </thead>
      <tbody>
        <RowOptions
          options={qdf.options}
          widgetName={widgetName}
          name={name}
          rows={rows}
          columns={columns}
          onChangeRows={onChangeRows}
          onSetAnswers={onSetAnswers()}
          answers={getAnswersType()}
        />
      </tbody>
    </table>
  );
};

export default RenderGridChild;
