import { IconButton } from '@mui/material';
import { IResponseOption } from '../../../types';
import GridHeader from './GridHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './ColumnOptions.module.css';
import { useResponseOptionsHandler, useResponseOptionsOrder } from '../../../hooks';

interface ColumnOptionsProps {
  columns?: IResponseOption[];
  onChangeColumns: (inputFields: IResponseOption[]) => void;
  options: Record<string, any>;
}

const ColumnOptions = ({ columns = [], onChangeColumns, options }: ColumnOptionsProps) => {
  const { addResponses, removeResponses, responseTextHandler } = useResponseOptionsHandler({
    responseOptions: columns,
    onChange: onChangeColumns,
  });

  const columnsOrder = useResponseOptionsOrder({
    responseOptions: columns,
    options,
    nameOverrides: {
      order: 'colorder',
      sample: 'colsample',
    },
  });

  return (
    <tr>
      <td></td>
      {columnsOrder.map((column: IResponseOption) => (
        <th
          className={styles['header-cell']}
          align="center"
          scope="column"
          style={{ width: options.width > 0 && options.width }}
          key={column.code}
          data-testid={`grid-column-${column.code}`}>
          <GridHeader
            cell={column}
            index={column.code}
            scope="column"
            onChangeText={responseTextHandler}
            onRemove={removeResponses}
          />
        </th>
      ))}
      <td align="right">
        <IconButton color="success" onClick={addResponses}>
          <AddCircleIcon data-testid="add-column" />
        </IconButton>
      </td>
    </tr>
  );
};

export default ColumnOptions;
