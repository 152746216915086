import { Button, IconButton, Stack } from '@mui/material';
import { getSortedArr } from '../../../containers/WidgetBuilder/util';
import { IResponseOption, QdfQuestion } from '../../../types';
import GridHeader from './GridHeader';
import GridButton from './GridButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useResponseOptionsHandler } from '../../../hooks';
import GridOpen from './GridOpen';

interface CollapsedColumnOptionsProps {
  widgetName: string;
  options: Record<string, any>;
  columns?: IResponseOption[];
  onChangeColumns: (inputFields: IResponseOption[]) => void;
  row: QdfQuestion;
  onSetAnswers: Function;
  answers: Record<string, number | string | null>;
}

const CollapsedColumnOptions = ({
  widgetName,
  options,
  columns = [],
  onChangeColumns,
  row,
  answers,
  onSetAnswers,
}: CollapsedColumnOptionsProps) => {
  const { addResponses, removeResponses, responseTextHandler } = useResponseOptionsHandler({
    responseOptions: columns,
    onChange: onChangeColumns,
  });

  const isGridOpen = widgetName === 'grid-open';

  const orderColumns = () =>
    options['custom_colorder'] && options['custom_colorder'].match('^[0-9,]*$')
      ? getSortedArr(columns, options['custom_colorder'])
      : columns;

  return (
    <Stack>
      <Stack>
        {orderColumns().map((column: IResponseOption) => (
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            key={`${row.name}-${column.code}`}>
            {!isGridOpen && <GridButton widgetName={widgetName} column={column} row={row} />}
            <GridHeader
              cell={column}
              key={column.code}
              index={column.code}
              scope="column"
              onChangeText={responseTextHandler}
              onRemove={removeResponses}
              textAlign="left"
              hideRemoveBtn
              width="100%"
            />
            {isGridOpen && (
              <GridOpen
                type={options.colrank || options.rowrank ? 'number' : 'text'}
                row={row}
                column={column}
                onSetAnswers={onSetAnswers}
                answer={
                  answers[`${row.name}-${column.code}`] == null
                    ? ''
                    : answers[`${row.name}-${column.code}`]
                }
              />
            )}
            <IconButton color="error" onClick={() => removeResponses(column.code)}>
              <RemoveCircleIcon data-testid={`remove-row`} />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <Button color="success" onClick={addResponses}>
        Add column
      </Button>
    </Stack>
  );
};

export default CollapsedColumnOptions;
