import { Autocomplete, AutocompleteRenderGetTagProps, Chip, TextField } from '@mui/material';
import React from 'react';
import { QdfQuestion } from '../../types';

interface ListOfIntControlProps {
  optionName: string;
  onOptionChange: Function;
  qdf: QdfQuestion;
}

const ListOfIntControl = ({ optionName, onOptionChange, qdf }: ListOfIntControlProps) => {
  const value = typeof qdf.options[optionName] === 'object' ? qdf.options[optionName] : [];

  const onChange = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
    onOptionChange({
      ...qdf.options,
      [optionName]: value,
    });
  };

  const onRenderTags = (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
    value.map((option: string, index: number) => (
      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
    ));

  return (
    <Autocomplete
      data-testid="listOfInt-input"
      fullWidth
      size="small"
      multiple
      freeSolo
      options={[]}
      value={value}
      onChange={onChange}
      renderTags={onRenderTags}
      renderInput={(params) => <TextField {...params} variant="outlined" label={optionName} />}
    />
  );
};

export default ListOfIntControl;
