import { IconButton } from '@mui/material';
import { useGridRowsHandler, useOffsetHandler } from '../../../hooks';
import { IResponseOption, QdfQuestion } from '../../../types';
import GridButton from './GridButton';
import GridHeader from './GridHeader';
import GridOpen from './GridOpen';
import styles from './RowOptions.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getSortedArr, showSample } from '../../../containers/WidgetBuilder/util';

interface OffsetRowsProps {
  widgetName: string;
  name: string;
  rows?: QdfQuestion[];
  columns?: IResponseOption[];
  onChangeRows: (inputFields: QdfQuestion[]) => void;
  options: Record<string, any>;
  onSetAnswers: Function;
  answers: Record<string, number | string | null>;
}

const OffsetRows = ({
  name,
  widgetName,
  rows = [],
  onChangeRows,
  columns = [],
  options,
  onSetAnswers,
  answers,
}: OffsetRowsProps) => {
  const { addRows, rowTextHandler, removeRows } = useGridRowsHandler({ name, rows, onChangeRows });

  const offset = options.offset;
  const offsetOrder = useOffsetHandler({ columns, offset });

  const orderRows = () => {
    const order =
      options['custom_roworder'] && options['custom_roworder'].match('^[0-9,]*$')
        ? getSortedArr(rows, options['custom_roworder'])
        : rows;

    //Rowsample provides the number of rows to be randomly shown
    if (options.rowsample) return showSample(order, options.rowsample);

    //Displaymax provides the number of rows to be shown
    if (options.displaymax) {
      return order.filter((item: QdfQuestion, index: number) => index < options.displaymax);
    }
    return order;
  };
  return (
    <>
      {orderRows().map((row: QdfQuestion, index: number) => (
        <tr
          data-testid="offset-rows"
          className={options.stripes && index % 2 === 0 ? styles['row-odd'] : styles.row}
          key={row.name}>
          {offsetOrder.map((column) =>
            column.code === -1 ? (
              <th
                key={`${row.name}-${column.code}`}
                className={styles['header-cell']}
                align="center"
                scope="row">
                <GridHeader
                  cell={row}
                  scope="row"
                  index={Number(row.name.split('_')[1])}
                  onChangeText={rowTextHandler}
                  onRemove={removeRows}
                />
              </th>
            ) : widgetName === 'grid-open' ? (
              <td className={styles['table-cell']} key={`${row.name}-${column.code}`}>
                <GridOpen
                  type={options.colrank || options.rowrank ? 'number' : 'text'}
                  row={row}
                  column={column}
                  answer={
                    answers[`${row.name}-${column.code}`] == null
                      ? ''
                      : answers[`${row.name}-${column.code}`]
                  }
                  onSetAnswers={onSetAnswers}
                />
              </td>
            ) : (
              <td className={styles['table-cell']} key={`${row.name}-${column.code}`}>
                <GridButton
                  widgetName={widgetName}
                  column={column}
                  row={row}
                  onSetAnswers={onSetAnswers}
                  checked={
                    widgetName === 'grid-check'
                      ? answers[`${row.name}-${column.code}`] === column.code
                      : answers[row.name] === column.code
                  }
                />
              </td>
            )
          )}
        </tr>
      ))}
      <tr>
        <td>
          <IconButton onClick={addRows} color="success">
            <AddCircleIcon data-testid="add-row" />
          </IconButton>
        </td>
      </tr>
    </>
  );
};

export default OffsetRows;
