import { IconButton } from '@mui/material';
import { IResponseOption, QdfQuestion } from '../../../types';
import GridHeader from './GridHeader';
import GridButton from './GridButton';
import styles from './RowOptions.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GridOpen from './GridOpen';
import {
  getSortedArr,
  getSubstringsBetweenParens,
  randomizeArr,
  reverseArr,
  rotateArr,
  showSample,
} from '../../../containers/WidgetBuilder/util';
import { useGridRowsHandler, useResponseOptionsOrder } from '../../../hooks';
import { useMemo } from 'react';

interface RowOptionsProps {
  widgetName: string;
  name: string;
  rows?: QdfQuestion[];
  columns?: IResponseOption[];
  onChangeRows: (inputFields: QdfQuestion[]) => void;
  options: Record<string, any>;
  onSetAnswers: Function;
  answers: Record<string, number | string | null>;
}

const RowOptions = ({
  name,
  widgetName,
  rows = [],
  onChangeRows,
  columns = [],
  options,
  onSetAnswers,
  answers,
}: RowOptionsProps) => {
  const { addRows, rowTextHandler, removeRows } = useGridRowsHandler({ name, rows, onChangeRows });

  const rowsOrder = useResponseOptionsOrder({
    responseOptions: rows,
    options,
    nameOverrides: { order: 'roworder', sample: 'rowsample' },
  });

  return (
    <>
      {rowsOrder.map((row: QdfQuestion, index: number) => (
        <tr
          className={options.stripes && index % 2 === 0 ? styles['row-odd'] : styles.row}
          key={row.name}
          data-testid={`grid-row-${index}`}>
          <th className={styles['header-cell']} align="center" scope="row">
            <GridHeader
              cell={row}
              scope="row"
              index={Number(row.name.split('_')[1])}
              onChangeText={rowTextHandler}
              onRemove={removeRows}
            />
          </th>
          {(options.colsample ? showSample(columns, options.colsample) : columns)?.map((column) => (
            <td className={styles['table-cell']} key={`${row.name}-${column.code}`}>
              {widgetName === 'grid-open' ? (
                <GridOpen
                  type={options.colrank || options.rowrank ? 'number' : 'text'}
                  answer={
                    answers[`${row.name}-${column.code}`] == null
                      ? ''
                      : answers[`${row.name}-${column.code}`]
                  }
                  row={row}
                  column={column}
                  width={options['input_width']}
                  onSetAnswers={onSetAnswers}
                />
              ) : (
                <GridButton
                  widgetName={widgetName}
                  column={column}
                  row={row}
                  onSetAnswers={onSetAnswers}
                  checked={
                    widgetName === 'grid-check'
                      ? answers[`${row.name}-${column.code}`] === column.code
                      : answers[row.name] === column.code
                  }
                />
              )}
            </td>
          ))}
        </tr>
      ))}
      <tr>
        <td>
          <IconButton onClick={addRows} color="success">
            <AddCircleIcon data-testid="add-row" />
          </IconButton>
        </td>
      </tr>
    </>
  );
};

export default RowOptions;
