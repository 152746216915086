import { useEffect, useState } from 'react';
import { IResponseOption } from '../types';

export const useMultiAnswersHandler = (
  responseOptions: IResponseOption[],
  options: Record<string, any>
) => {
  const [multiAnswers, setMultiAnswers] = useState<Record<number, boolean>>({});

  const checkedAnswersArr = Object.keys(multiAnswers).reduce((acc: string[], current: string) => {
    if (multiAnswers[Number(current)]) return [...acc, current];
    return acc;
  }, []);

  const onSelectMultiAnswer = (index: number) => {
    const answersAfterAddingOption = {
      ...multiAnswers,
      [index]: !multiAnswers[index],
    };

    if (options.max) {
      if (checkedAnswersArr.length < options.max || multiAnswers[index])
        setMultiAnswers(answersAfterAddingOption);
    } else setMultiAnswers(answersAfterAddingOption);
  };

  //If a checked option is removed, its value will become false
  for (let checkedAnswer of checkedAnswersArr) {
    if (responseOptions.findIndex((item) => item.code === Number(checkedAnswer)) === -1) {
      setMultiAnswers({ ...multiAnswers, [checkedAnswer]: false });
    }
  }

  useEffect(() => {
    if (options.max && checkedAnswersArr.length > options.max) {
      setMultiAnswers({});
    }
  }, [options.max]);

  return { multiAnswers, onSelectMultiAnswer };
};
