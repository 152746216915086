import { IconButton } from '@mui/material';
import { getSortedArr, showSample } from '../../../containers/WidgetBuilder/util';
import { useGridRowsHandler } from '../../../hooks';
import { QdfQuestion } from '../../../types';
import GridHeader from './GridHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './ColumnOptions.module.css';

interface TransposedGridRowsProps {
  rows?: QdfQuestion[];
  onChangeRows: (inputFields: QdfQuestion[]) => void;
  options: Record<string, any>;
  name: string;
}

const TransposedGridRows = ({
  rows = [],
  onChangeRows,
  options,
  name,
}: TransposedGridRowsProps) => {
  const { addRows, rowTextHandler, removeRows } = useGridRowsHandler({ name, rows, onChangeRows });

  const orderRows = () => {
    const order =
      options['custom_roworder'] && options['custom_roworder'].match('^[0-9,]*$')
        ? getSortedArr(rows, options['custom_roworder'])
        : rows;

    //Rowsample provides the number of rows to be randomly shown
    if (options.rowsample) return showSample(order, options.rowsample);

    //Displaymax provides the number of rows to be shown
    if (options.displaymax) {
      return order.filter((item: QdfQuestion, index: number) => index < options.displaymax);
    }
    return order;
  };

  return (
    <tr data-testid="transposed-rows">
      <td></td>
      {orderRows().map((row: QdfQuestion) => (
        <th
          className={styles['header-cell']}
          align="center"
          scope="row"
          style={{ width: options.width > 0 && options.width }}
          key={row.name}>
          <GridHeader
            cell={row}
            index={Number(row.name.split('_')[1])}
            scope="row"
            onChangeText={rowTextHandler}
            onRemove={removeRows}
          />
        </th>
      ))}
      <td align="right">
        <IconButton color="success" onClick={addRows}>
          <AddCircleIcon data-testid="add-row" />
        </IconButton>
      </td>
    </tr>
  );
};

export default TransposedGridRows;
