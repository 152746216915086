import { useState } from 'react';
import { IResponseOption, QdfQuestion } from '../types';

export const useGridAnswersHandler = (qdf: QdfQuestion) => {
  const [answers, setAnswers] = useState<Record<string, number | null>>({});

  const onSelectAnswer = (rowName: string, colCode: number) => {
    const answersAfterAddingOption = {
      ...answers,
      [rowName]: colCode,
    };
    setAnswers(answersAfterAddingOption);
    if (qdf.options.unique) {
      for (const answer in answersAfterAddingOption) {
        if (
          answer !== rowName &&
          answersAfterAddingOption[answer] === answersAfterAddingOption[rowName]
        ) {
          setAnswers({ ...answersAfterAddingOption, [answer]: null });
        }
      }
    }
  };
  return { answers, onSelectAnswer };
};

interface useGridCheckAnswersHandlerArgs {
  qdf: QdfQuestion;
  rows?: QdfQuestion[];
  columns?: IResponseOption[];
}

export const useGridCheckAnswersHandler = ({ qdf, rows = [] }: useGridCheckAnswersHandlerArgs) => {
  const [gridCheckAnswers, setGridCheckAnswers] = useState<Record<string, number | null>>({});

  const onSelectGridCheckAnswer = (rowName: string, colCode: number) => {
    const id = `${rowName}-${colCode}`;

    const answersAfterAddingOption = {
      ...gridCheckAnswers,
      [id]: colCode,
    };
    setGridCheckAnswers(answersAfterAddingOption);
    if (qdf.options.unique) {
      for (let row of rows) {
        if (
          row.name !== rowName &&
          answersAfterAddingOption[`${row.name}-${colCode}`] ===
            answersAfterAddingOption[`${rowName}-${colCode}`]
        ) {
          setGridCheckAnswers({ ...answersAfterAddingOption, [`${row.name}-${colCode}`]: null });
        }
      }
    }
  };
  return { gridCheckAnswers, onSelectGridCheckAnswer };
};
export const useGridOpenAnswersHandler = ({
  qdf,
  rows = [],
  columns = [],
}: useGridCheckAnswersHandlerArgs) => {
  const [gridOpenAnswers, setGridOpenAnswers] = useState<Record<string, number | string | null>>(
    {}
  );

  const onSelectGridOpenAnswer = (rowName: string, colCode: number, cellText: string) => {
    const id = `${rowName}-${colCode}`;

    const answersAfterAddingOption = {
      ...gridOpenAnswers,
      [id]: cellText,
    };
    setGridOpenAnswers(answersAfterAddingOption);
    if (qdf.options.colrank) {
      for (let row of rows) {
        if (
          row.name !== rowName &&
          answersAfterAddingOption[`${row.name}-${colCode}`] ===
            answersAfterAddingOption[`${rowName}-${colCode}`]
        ) {
          setGridOpenAnswers({ ...answersAfterAddingOption, [`${row.name}-${colCode}`]: null });
        }
      }
    }

    if (qdf.options.rowrank) {
      for (let column of columns) {
        if (
          column.code !== colCode &&
          answersAfterAddingOption[`${rowName}-${column.code}`] ===
            answersAfterAddingOption[`${rowName}-${colCode}`]
        ) {
          setGridOpenAnswers({ ...answersAfterAddingOption, [`${rowName}-${column.code}`]: null });
        }
      }
    }
  };

  return { gridOpenAnswers, onSelectGridOpenAnswer };
};
