import { IconButton, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface OptionInfoProps {
  type: string;
  description: string;
}

const OptionInfo = ({ type, description }: OptionInfoProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <span>
      <IconButton onClick={handleClick} color="info">
        <InfoOutlinedIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography sx={{ p: 2 }}>
          <strong>{type}</strong>
          <br />
          {description}
        </Typography>
      </Popover>
    </span>
  );
};
export default OptionInfo;
