import { IconButton, MenuItem, Select, Stack, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import AppCSS from './App.module.css';
import { WidgetBuilder } from './containers/WidgetBuilder/WidgetBuilder';
import { widgets } from './lib/widgets';
import logo from './YGlogo.png';
import { theme } from './theme/minimal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './state';
import { toggleOptionsPanel } from './state/root';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';

function App() {
  const dispatch = useDispatch();
  const [selectedWidget, setSelectedWidget] = useState(widgets[0].name);

  const shownOptionsPanel = useSelector((state: RootState) => state.root.shownOptionsPanel);

  return (
    <ThemeProvider theme={theme}>
      <div className={AppCSS.container}>
        <div className={AppCSS.logo}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <img src={logo} alt="YouGov Logo" height={25} />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Select
                value={selectedWidget}
                onChange={(e) => setSelectedWidget(e.target.value)}
                style={{ margin: '5px' }}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}>
                {widgets.map((widget) => (
                  <MenuItem key={widget.name} value={widget.name}>
                    {widget.displayName}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                size="large"
                color="primary"
                onClick={() => dispatch(toggleOptionsPanel())}>
                {shownOptionsPanel ? (
                  <CloseIcon fontSize="inherit" data-testid={'close-sidepanel'} />
                ) : (
                  <TuneIcon fontSize="inherit" data-testid={'open-sidepanel'} />
                )}
              </IconButton>
            </Stack>
          </Stack>
        </div>

        {/* WidgetBuilder doesn't handle widgetName being changed, so `key` is used to force re-mount */}
        <WidgetBuilder key={selectedWidget} widgetName={selectedWidget} name="go1" />
      </div>
    </ThemeProvider>
  );
}

export default App;
