import { QdfQuestion } from '../../../types';
import EditableQuestionText from '../../EditableQuestionText/EditableQuestionText';
import { Stack } from '@mui/material';
import styles from './GridWidget.module.css';
import RenderGridChild from './RenderGridChild';

interface GridProps {
  widgetName: string;
  name: string;
  onVisualChange: (question: QdfQuestion) => void;
  qdf: QdfQuestion;
}

const GridWidget = ({ onVisualChange, qdf, name, widgetName }: GridProps) => {
  const questionTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onVisualChange({
      ...qdf,
      text: e.target.value,
    });
  };

  return (
    <Stack spacing={4} flexWrap="wrap">
      <EditableQuestionText onChangeText={questionTextChangeHandler} value={qdf.text} />
      <div className={styles['grid-wrapper']}>
        <RenderGridChild
          widgetName={widgetName}
          name={name}
          onVisualChange={onVisualChange}
          qdf={qdf}
        />
      </div>
      {qdf.options.dk && (
        <label htmlFor="dk" data-testid="dk-label">
          <Stack
            className={styles['dk-wrapper']}
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <input type="checkbox" id="dk" className={styles['dk-input']} />

            <span>{qdf.options['dk_text']}</span>
          </Stack>
        </label>
      )}
    </Stack>
  );
};

export default GridWidget;
